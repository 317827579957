<template>
  <section class="w1220">
    <router-view />
  </section>
</template>

<script>

</script>

<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
